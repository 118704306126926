import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import './InfoSection.css';
import demo2 from '../assets/demo2.png';
import Popup from './Popup';

const InfoSection = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();

  const handleTryNowClick = () => {
    window.location.href = 'https://app.grafema.xyz';

    // Google Analytics 4 event tracking
    window.gtag('event', 'click', {
      'event_category': 'Button',
      'event_label': 'Try it now',
    });

    // Mixpanel event tracking
    mixpanel.track('Button Clicked', { button: 'Try it now' });
  };

  return (
    <div className="info-section">
      <div className="info-content">
        <h1>{t('same_email_question')}</h1>
        <h2>{t('lead_specific_message')}</h2>
        <p>{t('tool_description')}</p>
        <img src={demo2} alt='Screenshot of Grafema email tool' className='info-image2'></img>
        <button className="info-button" onClick={handleTryNowClick}>{t('try_it')}</button>
      </div>
      
        <img src={demo2} alt='Screenshot of Grafema email tool' className='info-image'></img>
        {showPopup && <Popup onClose={() => setShowPopup(false)} />}
      
    </div>
  );
};

export default InfoSection;
