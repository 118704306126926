import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import i18n from './i18n'; // Ensure this is imported
import './App.css';
import demoVideo from './assets/demo.mp4';
import Popup from './components/Popup';
import InfoSection from './components/InfoSection';
import Footer from './components/Footer';
import NotFound from './components/404';
import Pricing from './components/Pricing'; // Import the Pricing component
import Header from './components/Header'; // Import the Header component
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

function Home({ lang }) {
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang).then(() => {
      console.log("Language changed!");
    });
  }, [lang]);

  const handleTryNowClick = () => {
    window.location.href = 'https://app.grafema.xyz';

    // Google Analytics 4 event tracking
    window.gtag('event', 'click', {
      'event_category': 'Button',
      'event_label': 'Try it now',
    });

    // Mixpanel event tracking
    mixpanel.track('Button Clicked', { button: 'Try it now' });
  };

  useEffect(() => {
    // Google Analytics 4 tracking code
    const ga4TrackingId = `${process.env.REACT_APP_GA4_MEASUREMENT}`; // Replace with your actual measurement ID

    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${ga4TrackingId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize the Google Analytics tracking
    window.dataLayer = window.dataLayer || [];
    function gtag(...args) {
      window.dataLayer.push(args);
    }
    gtag('js', new Date());
    gtag('config', ga4TrackingId);

    // Initialize Mixpanel
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, { debug: true, track_pageview: true, persistence: 'localStorage' });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Grafema - AI Personalized Cold Emails</title>
        <meta name="description" content="Grafema: Find new clients for your digital agency with AI-powered outreach. Send 100% personalized cold emails and increase response rates." />
        <meta name="keywords" content="AI-powered emails, personalized email marketing, cold email tool, web scraping email tool, automated email outreach, business email personalization, lead generation with AI, Grafema" />
        <link rel="canonical" href="https://grafema.xyz/" />
      </Helmet>
      <Header handleTryNowClick={handleTryNowClick} />
      <div className='hero'>
        <h1>{t('main_title')} <span className="underline"> {t('main_title2')}</span>{t('main_title3')} <br></br>{t('main_title4')}</h1>
        <h2>{t('main_subtitle')}<span> {t('with_ai')}</span>{t('main_subtitle2')}</h2>
      </div>
      <div className="tool">
        <video controls autoPlay loop muted playsInline>
          <source src={demoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {showPopup && <Popup onClose={() => setShowPopup(false)} />}
      <InfoSection />
    </div>
  );
}

function App({ lang }) {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home lang={lang} />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer /> {/* Ensure the footer is included here */}
    </div>
  );
}

export default App;
