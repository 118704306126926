import React from 'react';
import { useTranslation } from 'react-i18next';
import './Switcher.css'

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;  // Get the current language

  return (
    <div>
      {currentLanguage === 'pt' ? (
        <button className='button-lang' onClick={() => i18n.changeLanguage('en')}>EN</button>
      ) : (
        <button className='button-lang' onClick={() => i18n.changeLanguage('pt')}>PT</button>
      )}
    </div>
  );
}

export default LanguageSwitcher;
