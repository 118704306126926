import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './Switcher';
import logo from '../assets/longLogo.png';
import './Header.css';

function Header({ handleTryNowClick }) {
  const { t } = useTranslation();

  return (
    <div className="header">
      <Link to="/">
        <img className='logo' alt='logo' src={logo} />
      </Link>
      <div className="header-right">
        <LanguageSwitcher />
        <Link to="/pricing" className="link-pricing">{t('pricing')}</Link>
        <button className="button-google" onClick={handleTryNowClick}>
          {t('try_now')}
        </button>
      </div>
    </div>
  );
}

export default Header;
