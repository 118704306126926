import React from 'react';
import { useTranslation } from 'react-i18next';
import './Pricing.css';
import Header from './Header'; // Import the Header component

function Pricing() {
  const { t } = useTranslation();

  const handleTryNowClick = () => {
    window.location.href = 'https://app.grafema.xyz';
  };

  return (
    <div className="App">
      <Header handleTryNowClick={handleTryNowClick} />
      <div className="pricing-page">
        <h1 className="pricing-title">{t('plans_title')}</h1>
        <p className="pricing-subtitle">{t('plans_subtitle')}</p>
        <div className="subscription-container">
          <div className="plan-card free-plan">
            <h3>{t('grafema_free')}</h3>
            <ul>
              <li>{t('free_plan_email_quota')}</li>
              <li>{t('analytics_beta')}</li>
            </ul>
            <p className="price">{t('free')}</p>
            <button className="free-plan-button" onClick={handleTryNowClick}>{t('get_started')}</button>
          </div>
          <div className="plan-card pro-plan">
            <h3>{t('grafema_pro')}</h3>
            <ul>
              <li>{t('pro_plan_email_quota')}</li>
              <li>{t('analytics')}</li>
            </ul>
            <p className="price">€49 / {t('month')}</p>
            <button onClick={handleTryNowClick}>{t('get_started')}</button>
          </div>
          <div className="plan-card ultimate-plan">
            <h3>{t('grafema_ultimate')}</h3>
            <ul>
              <li>{t('ultimate_plan_email_quota')}</li>
              <li>{t('analytics')}</li>
            </ul>
            <p className="price">€99 / {t('month')}</p>
            <button onClick={handleTryNowClick}>{t('get_started')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
