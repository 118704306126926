import React, { useState, useEffect, useRef } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import './Popup.css';

function Popup({ onClose }) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('');
  const popupRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mixpanel tracking
    mixpanel.track("Form Submission", {
      email: email
    });

    const apiUrl = `https://api.airtable.com/v0/appEmiUZpCRXP7dFC/Waitlist`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
        },
        body: JSON.stringify({
          fields: {
            Email: email,
          },
        }),
      });

      if (response.ok) {
        setMessage(t('thanks_message'));
        setEmailError('');
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setMessage(t('submission_error'));
        setEmailError('');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(t('error_message'));
      setEmailError('');
    }
  };

  return (
    <div className="popup" ref={popupRef}>
      <div className="popup-content">
        {message ? (
          <h1 className='title'>{message}</h1>
        ) : (
          <>
            <h1 className='title'>{t('join_waitlist_title')}</h1>
            <h2>{t('join_waitlist_subtitle')}</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder={t('email_placeholder')}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(''); // Clear error message when user modifies email
                }}
                required
              />
              <button type="submit">{t('register')}</button>
            </form>
            <button className="close-btn" onClick={onClose}>
              ✖
            </button>
          </>
        )}
        {emailError && <div style={{ color: 'red', marginTop: '10px' }}>{emailError}</div>}
      </div>
      
    </div>
  );
}

export default Popup;