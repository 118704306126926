import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FaLinkedin } from 'react-icons/fa'; // Import LinkedIn icon
import { FaXTwitter } from 'react-icons/fa6'; // Import X (Twitter) icon

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer-left'>
        <p>© 2024 Grafema. All rights reserved.</p>
      </div>
      <div className='footer-right'>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdEypkR29-lmab6wqGXDnF4a_0ZRWhN_-ecsmvvWW4-alclRA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Report Bug</a>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdDbncT_z2rkmM_nTWJ3xKQiuC_bINjdCcfuctDKy-N-QajwA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Give Feedback</a>
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/grafema-ai" target="_blank" rel="noopener noreferrer" className="social-icon linkedin-icon">
            <FaLinkedin />
          </a>
          <a href="https://x.com/grafema_ai" target="_blank" rel="noopener noreferrer" className="social-icon x-icon">
            <FaXTwitter />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
